import { Scene } from "../Scene";
import { ScenesRestService } from "../rest/ScenesRestService";

export class ScenesService {
  public listScenes(): Promise<Scene[]> {
    return new ScenesRestService().listScenes();
  }

  public activateScene(id: string): Promise<void> {
    return new ScenesRestService().activateScene(id);
  }
}