import { Node, UpdateNodeProps } from "../Node";
import { NodesRestService } from "../rest/NodesRestService";

export class NodesService {
  public async listNodes(): Promise<Node[]> {
    return new NodesRestService().listNodes();
  }

  public updateNode(id: string, props: UpdateNodeProps): Promise<Node> {
    return new NodesRestService().updateNode(id, props);
  }

  public executeAction(id: string, action: string): Promise<void> {
    return new NodesRestService().executeAction(id, action);
  }
}