import { Scene } from "../Scene";
import { RestService } from "../../rest/RestService";

export class ScenesRestService extends RestService {
  constructor() {
    super('/scenes');
  }

  public listScenes(): Promise<Scene[]> {
    return this.doFetchAndParseJson(this.url(''));
  }

  public activateScene(id: string): Promise<void> {
    return this.doFetch(this.url(`/${id}/activate`), {
      method: 'POST'
    });
  }
}