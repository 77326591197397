import { useScenes } from "../../providers/ScenesProvider";
import { DashboardSceneItem } from "../item/DashboardSceneItem";

export function ScenesOverviewSection() {
  const { scenes } = useScenes();

  return (
    <div className="grid gap-2 md:gap-3 grid-cols-2">
      {scenes.map(scene => <DashboardSceneItem key={scene.id} scene={scene} />)}
    </div>
  );
}