import { Node, UpdateNodeProps } from "../Node";
import { RestService } from "../../rest/RestService";

export class NodesRestService extends RestService {
  constructor() {
    super('/nodes');
  }

  public listNodes(): Promise<Node[]> {
    return this.doFetchAndParseJson(this.url(''));
  }

  public updateNode(id: string, props: UpdateNodeProps): Promise<Node> {
    return this.doFetchAndParseJson(this.url(`/${id}`), {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(props)
    });
  }

  public executeAction(id: string, action: string): Promise<void> {
    return this.doFetch(this.url(`/${id}/actions/${action}/execute`), {
      method: 'POST'
    });
  }
}