import * as React from 'react';
import { ThemeToggle } from '@strotzio/react-ui';
import { Link, useLocation } from 'react-router-dom';
import t from '../../services/i18n/TranslationService';
import { useScrollYPosition } from 'react-use-scroll-position';

export function Header() {
  const [scrolled, setScrolled] = React.useState<boolean>(false);
  const scrollY = useScrollYPosition();

  React.useEffect(() => {
    setScrolled(scrollY > 0);
  }, [scrollY]);

  return (
    <header className={`sticky top-0 z-10 ${scrolled ? 'bg-primary shadow-2xl' : ''}`}>
      <section className="p-5 max-w-7xl mx-auto flex items-center justify-end space-x-5">
        <nav className="flex space-x-3">
          <ScreenLink route="/" text={t('TAB_DASHBOARD')} />
          <ScreenLink route="/devices" text={t('TAB_DEVICES')} />
        </nav>
        <div className="flex items-center space-x-3">
          <ThemeToggle />
        </div>
      </section>
    </header>
  );
}

function ScreenLink(props: { route: string, text: string }) {
  const location = useLocation();
  const active = props.route === location.pathname;
  const className = active ? "text-primary" : "text-secondary";

  return (
    <div className={className}>
      <Link
        to={props.route}
        children={props.text}
        className="text-base xl:text-lg font-semibold hover:text-primary transition ease-in duration-200"
      />
    </div>
  );
}