import { Scene } from "../../Scene";
import { Clickable, Text } from "@strotzio/react-ui";
import { ScenesService } from "../../services/ScenesService";

export function DashboardSceneItem(props: { scene: Scene }) {

  const activate = () => {
    new ScenesService().activateScene(props.scene.id);
  };

  return (
    <Clickable onClick={activate} className="flex rounded-md items-center justify-center bg-secondary p-3">
      <Text.PrimaryBold>{props.scene.name}</Text.PrimaryBold>
    </Clickable>
  );
}