import React from 'react';
import { App } from './App';
import ReactDOM from 'react-dom';
import { StandardProviders } from '@strotzio/react-ui';
import { NodesProvider } from './nodes/providers/NodesProvider';
import { ScenesProvider } from './scenes/providers/ScenesProvider';

ReactDOM.render(
  <React.StrictMode>
    <StandardProviders>
      <NodesProvider>
        <ScenesProvider>
          <App />
        </ScenesProvider>
      </NodesProvider>
    </StandardProviders>
  </React.StrictMode >,
  document.getElementById('root')
);