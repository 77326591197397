import { GATEWAY_URL } from '../env.json';
import { ApiException } from './ApiException';

export class RestService {
    constructor(private baseUrl: string) { }

    protected url(url: string): string {
        return GATEWAY_URL + this.baseUrl + url;
    }

    protected async doFetch(input: RequestInfo, init?: RequestInit): Promise<void> {
        const response = await this.doFetchAndCatch(input, init);
        const responseText = await response.text();
        if (response.status !== 200) {
            throw JSON.parse(responseText); // ApiException
        }
    }

    protected async doFetchAndParseJson(input: RequestInfo, init?: RequestInit): Promise<any> {
        const response = await this.doFetchAndCatch(input, init);
        const responseText = await response.text();
        if (response.status !== 200) {
            throw JSON.parse(responseText); // ApiException
        }
        return JSON.parse(responseText);
    }

    private async doFetchAndCatch(input: RequestInfo, init?: RequestInit): Promise<Response> {
        try {
            return await fetch(input, init);
        } catch {
            throw {
                message: 'Server nicht erreichbar'
            } as ApiException;
        }
    }
}