import * as React from "react";
import { Centered, Title } from "@strotzio/react-ui";

interface ClockState {
  date: Date;
}

export class Clock extends React.Component<any, ClockState, any> {
  private interval: any;

  constructor(props: any) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ date: new Date() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const day = this.state.date.toLocaleString([], { dateStyle: 'medium' });
    const time = this.state.date.toLocaleString([], { timeStyle: 'short' });
    return (
      <Centered.Col className="space-y-3">
        <Title.Primary className="text-secondary">{day}</Title.Primary>
        <Title.Primary className="text-8xl">{time}</Title.Primary>
      </Centered.Col>
    );
  }
}