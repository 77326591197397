import './app.css';
import { Routes } from './navigation/Routes';
import { Layout } from '@strotzio/react-ui';
import { Header } from './components/header/Header';

export function App() {
    return (
        <Layout.Vertical>
            <Header />
            <Routes />
        </Layout.Vertical>
    );
}