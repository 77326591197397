import React from 'react';
import { Scene } from '../Scene';
import { ScenesService } from '../services/ScenesService';

export interface ScenesState {
  scenes: Scene[];
}

const ScenesContext = React.createContext<ScenesState>({
  scenes: []
});

export const ScenesProvider = (props: { children: React.ReactNode }) => {
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timer>();
  const [scenes, setScenes] = React.useState<Scene[]>([]);

  const refreshCallback = React.useCallback(async () => {
    new ScenesService().listScenes().then(setScenes);
  }, []);

  React.useEffect(() => {
    refreshCallback();
    const interval = setInterval(() => refreshCallback(), 1000);
    setIntervalId(interval);
  }, [refreshCallback]);

  React.useEffect(() => {
    if (scenes.length !== 0) {
      clearInterval(intervalId);
    }
  }, [scenes]);

  return (
    <ScenesContext.Provider value={{ scenes }}>
      {props.children}
    </ScenesContext.Provider>
  );
};

export function useScenes(): ScenesState {
  return React.useContext(ScenesContext);
}