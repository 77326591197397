import { Node, OnOffState } from "../../Node";
import { NodeOnlinePill } from "./NodeOnlinePill";
import { Clickable, Text } from "@strotzio/react-ui";
import { NodesService } from "../../services/NodesService";
import { ReactComponent as BulbSvg } from '../../../assets/images/bulb.svg';

export function DashboardNodeItem(props: { node: Node }) {
  const state: OnOffState = props.node.state.ON_OFF || { on: false };

  const toggle = () => {
    new NodesService().executeAction(props.node.id, 'ON_OFF:TOGGLE');
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      <Clickable onClick={toggle} className={`flex w-24 h-24 rounded-full items-center justify-center ${state.on ? "bg-accent" : "bg-secondary"}`}>
        <BulbSvg
          className="bulb w-16 h-16"
          style={{ color: "white" }}
        />
      </Clickable>
      <div className="flex space-x-2 items-center">
        <Text.PrimaryBold className="text-center">{props.node.name}</Text.PrimaryBold>
        {!props.node.online && <NodeOnlinePill node={props.node} />}
      </div>
    </div>
  );
}