import { DevicesScreen } from '../screens/DevicesScreen';
import { Route, Routes as ROUTES } from 'react-router-dom';
import { DashboardScreen } from '../screens/DashboardScreen';

export function Routes() {
  return (
    <div>
      <div className="p-5 max-w-7xl mx-auto">
        <ROUTES>
          <Route path="/" element={<DashboardScreen />} />
          <Route path="/devices" element={<DevicesScreen />} />
        </ROUTES>
      </div>
    </div>
  );
}