import { MESSAGING_VIRTUAL_HOST } from '../env.json';

export interface Channel {
  name: string;
}

export function nodesUpdated(): Channel {
  return withVirtualHost(`nodes.updated`);
}

function withVirtualHost(name: string): Channel {
  return {
    name: `${MESSAGING_VIRTUAL_HOST}.${name}`
  };
}