import { Node } from "../nodes/Node";
import icon from '../assets/images/icon.png';
import { NodesOverviewSection } from "../nodes/components/overview/NodesOverviewSection";
import { ScenesOverviewSection } from "../scenes/components/overview/ScenesOverviewSection";
import { Clock } from "../components/dashboard/Clock";

export function DashboardScreen() {
  const predicate = (node: Node) => node.showOnDashboard;
  return (
    <div className="grid gap-5 md:gap-5 grid-cols-1 lg:grid-cols-2">

      <div className="flex-1 flex flex-col space-y-5">
        <div className="flex justify-center items-center hidden lg:flex">
          <img src={icon} className="w-36" />
        </div>
        <div className="flex-1 flex justify-center items-center">
          <Clock />
        </div>
        <ScenesOverviewSection />
      </div>

      <div className="flex-1">
        <NodesOverviewSection predicate={predicate} />
      </div>

    </div>
  );
}