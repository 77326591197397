import de from '../../assets/i18n/de.json';

interface Language {
    locale: string;
    translations: any;
}

const DE: Language = { locale: 'de', translations: de };

const allLanguages: Language[] = [DE];
const defaultLanguage: Language = DE;

function getCurrentLanguage(): Language {
    const supportedBrowserLocles = getBrowserLocales()
        .filter(locale => allLanguages.some(language => language.locale === locale));
    const locale = supportedBrowserLocles.length > 0 ? supportedBrowserLocles[0] : defaultLanguage.locale;
    return allLanguages.find(language => locale.toLowerCase().includes(language.locale)) || defaultLanguage;
}

function getBrowserLocales(): string[] {
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return [];
    }

    return browserLocales
        .map(locale => locale.trim())
        .map(locale => locale.split(/-|_/)[0]);
}

export default function getTranslation(key: string, replacements?: any): string {
    let translation: string = getCurrentLanguage().translations[key] || key;
    if (replacements) {
        for (const replacement of Object.entries(replacements)) { // TODO use typesafe interface
            translation = translation.replace('{{' + replacement[0] + '}}', '' + replacement[1]);
        }
    }
    return translation;
}