import { Node } from '../../Node';
import { useNodes } from '../../providers/NodesProvider';
import t from '../../../services/i18n/TranslationService';
import { Modal, Spinner, Text } from '@strotzio/react-ui';
import { DashboardNodeItem } from '../common/DashboardNodeItem';

interface NodesOverviewSectionProps {
  predicate: (node: Node) => boolean;
}

export function NodesOverviewSection(props: NodesOverviewSectionProps) {
  const { nodes } = useNodes();

  if (nodes.length === 0) {
    return (
      <Modal visible={true} onClose={() => { }}>
        <div className="flex-1 flex flex-col items-center justify-center">
          <div className="p-3">
            <Spinner />
          </div>
          <Text.Tertiary>{t('NODES_WAITING_FOR_NODES')}</Text.Tertiary>
        </div>
      </Modal>
    );
  }

  return (
    <div className="grid gap-2 md:gap-3 grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 items-stretch">
      {nodes.filter(node => props.predicate(node)).map(node => <DashboardNodeItem key={node.id} node={node} />)}
    </div>
  );
}