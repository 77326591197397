import React from 'react';
import mqtt from 'mqtt';
import { Channel } from '../Channels';
import { MESSAGING_URL, MESSAGING_USER, MESSAGING_PASSWORD } from '../../env.json';

interface MessageSubscriberProps {
  channel: Channel;
  onMessage: (message: any) => void;
}

export function MessageSubscriber(props: MessageSubscriberProps) {
  React.useEffect(() => {
    const onMessage = (payload: string) => {
      const message = JSON.parse(payload);
      console.log(message);
      props.onMessage(message);
    }
    const options: mqtt.IClientOptions = {
      path: '/ws',
      username: MESSAGING_USER,
      password: MESSAGING_PASSWORD,
    };
    const client = mqtt.connect(MESSAGING_URL, options);
    client.subscribe(props.channel.name, () => {
      console.log(`subscribed to ${MESSAGING_URL} ${props.channel.name}`);
    });
    client.on('connect', () => console.log(`connected to ${MESSAGING_URL}`));
    client.on('disconnect', () => console.log(`disconnected from ${MESSAGING_URL}`));
    client.on('message', (_topic, payload) => onMessage(payload.toString()));
  }, [props.channel.name]);

  return <></>;
}