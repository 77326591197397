import React from 'react';
import { Node, NodesUpdatedMessage } from '../Node';
import { NodesService } from '../services/NodesService';
import { nodesUpdated } from '../../messaging/Channels';
import { MessageSubscriber } from '../../messaging/components/MessageSubscriber';

export interface NodesState {
  nodes: Node[];
}

const NodesContext = React.createContext<NodesState>({
  nodes: []
});

export const NodesProvider = (props: { children: React.ReactNode }) => {
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timer>();
  const [nodes, setNodes] = React.useState<Node[]>([]);

  const refreshCallback = React.useCallback(async () => {
    new NodesService().listNodes().then(setNodes);
  }, []);

  React.useEffect(() => {
    refreshCallback();
    const interval = setInterval(() => refreshCallback(), 1000);
    setIntervalId(interval);
  }, [refreshCallback]);

  React.useEffect(() => {
    if (nodes.length !== 0) {
      clearInterval(intervalId);
    }
  }, [nodes]);

  const onNodesUpdated = (message: NodesUpdatedMessage) => {
    setNodes(message.nodes);
  }

  return (
    <NodesContext.Provider value={{ nodes }}>
      <MessageSubscriber channel={nodesUpdated()} onMessage={onNodesUpdated} />
      {props.children}
    </NodesContext.Provider>
  );
};

export function useNodes(): NodesState {
  return React.useContext(NodesContext);
}